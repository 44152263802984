<template>
  <div class="conferenceppt-detail-container">
    <p class="invalid">该页面为预览效果，短期内将会失效</p>
    <div class="head">
      <div class="top">
        <span class="type">[会议成果]</span>
        <span class="title">{{ detailsData.title }}</span>
      </div>
      <IconButton1
        v-if="detailsData.id"
        :every="tempData"
        :curcer="detailsData.id"
      ></IconButton1>
      <div class="auther">
        作 者：
        <span>{{ detailsData.firstAuthor }}</span>
        <span v-for="(item, i) in detailsData.articleAuthorList" :key="i">
          {{ item.authorName }}&nbsp;
        </span>
        <span>{{ detailsData.communicationAuthor }}</span>
      </div>
      <div class="unit">
        作者单位：
        <span>{{ detailsData.firstAuthorUnit }}</span>
        <span v-for="(item, i) in detailsData.articleAuthorList" :key="i"
          >{{ item.authorUnit }}&nbsp;</span
        >
        <span>{{ detailsData.communicationAuthorUnit }}</span>
      </div>
    </div>
    <ul class="content">
      <li v-if="detailsData.artSummary" class="msg">
        <div>摘要：</div>
        <span>
          {{ detailsData.artSummary }}
        </span>
      </li>
      <li v-if="detailsData.keyword" class="msg">
        <div>关键词：</div>
        <span>{{ detailsData.keyword }}</span>
      </li>
      <li v-if="detailsData.mettingName" class="msg">
        <div>会议名称：</div>
        <span>{{ detailsData.mettingName }}</span>
      </li>
      <li v-if="detailsData.mettingTime" class="msg">
        <div>会议时间：</div>
        <span>{{ detailsData.mettingTime | dayFormate }}</span>
      </li>
      <li v-if="detailsData.mettingAddress" class="msg">
        <div>会议地点：</div>
        <span>{{ detailsData.mettingAddress }}</span>
      </li>
      <li v-if="detailsData.mettingUnit" class="msg">
        <div>主办单位：</div>
        <span>{{ detailsData.mettingUnit }}</span>
      </li>
      <li v-if="detailsData.mettingLevel" class="msg">
        <div>会议级别：</div>
        <span>{{ detailsData.mettingLevel }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconButton1 from "../components/IconButton1.vue";
import details from "@/mixins/details.js";
export default {
  components: {
    IconButton1,
  },
  mixins: [details],
  data() {
    return {
      detailsData: {},
      otherList: [],
    };
  },
  computed: {
    ...mapState(["table"]),
  },
  created() {
    this.getDetails({
      articleType: this.$route.query.articleType,
      articleId: this.$route.query.articleId,
      projectId: 3,
    });
  },
};
</script>

<style lang="scss" scoped>
.conferenceppt-detail-container {
  .invalid {
    margin:0 auto 50px;
    text-align: center;
    width: 396px;
    height: 45px;
    line-height: 45px;
    background: #f9f9f9;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .head {
    height: 170px;
    border-bottom: 1px dashed #d6d6d6;
    .top {
      display: flex;
      align-items: center;
      .type {
        font-size: 30px;
        color: #999999;
      }
      .title {
        font-size: 30px;
        color: #333333;
        margin: 0 5px;
      }
      .column {
        display: inline-block;
        width: 76px;
        height: 30px;
        background: #00a4ff;
        border: 1px solid #00a4ff;
        border-radius: 11px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }
    .auther {
      font-size: 14px;
      color: #999999;
      margin-top: 14px;
      span {
        font-size: 14px;
        margin-right: 10px;
      }
    }
    .unit {
      margin-top: 14px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
  .content {
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 40px;
    .msg {
      margin-top: 39px;
      div {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      span {
        margin-top: 10px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
}
</style>
